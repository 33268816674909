<template>
    <div>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("REPORTS.STATISTICS")
          }}</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
            $tc("PRODUCT.SHOW_COUNT", paginatedProducts.length, {
              total: filtered_products.length,
            })
          }}</span>
                </h3>






                <div v-if="filtered_products.length">



          <span class="label label-lg label-inline mt-2 mr-2 label-secondary">{{
            total_tags + " " + $tc("TAG.TAGS")
          }}</span>
                    <span class="label label-lg label-inline mt-2 label-secondary">{{
            total_scans + " " + $tc("TAG.SCANS")
          }}</span>

            <div>
                                                                                       <a
                                                                                          v-on:click="downloadCSVData()"
                                                                                             class="btn btn-primary mt-2 mr-2"
                                                                                        >

                                                                                          {{ $t("REPORTS.EXPORT") }}
                                                                                                 </a>
                                                                                    </div>

                </div>
                <treeselect
                        :multiple="true"
                        :options="filter_options"
                        :disable-branch-nodes="true"
                        :show-count="true"
                        :placeholder="$t('REPORTS.FILTER')"
                        v-model="filter_selected"
                        class="mt-5 primary"
                >
                    <div slot="value-label" slot-scope="{ node }">
                        {{ node.raw.customLabel }}
                    </div>
                </treeselect>
               <div>
            Von: <input type="date" v-model="date_from" v-on:change="signalChange"/>
            Bis: <input type="date" v-model="date_to" v-on:change="signalChange" />
            </div>





            </div>

            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">

                <!--begin::Table-->
                <div class="table-responsive" v-if="filtered_products.length">
                    <table
                            class="table table-head-custom table-vertical-center"
                            id="kt_advance_table_widget_4"
                    >
                        <thead>
                        <tr class="text-left">
                            <th class="pl-0" style="min-width: 120px">
                                {{ $t("PRODUCT.NAME") }}
                            </th>
                            <th style="min-width: 110px">{{ $t("PRODUCT.SKU") }}</th>
                            <th style="min-width: 110px">{{ $t("PRODUCT.GENDER") }}</th>
                            <th style="min-width: 120px">{{ $t("PRODUCT.CATEGORY") }}</th>
                            <th style="min-width: 120px">
                                {{ $t("PRODUCT.SUB_CATEGORY") }}
                            </th>
                            <th class="text-right" style="min-width: 60px">
                                {{ $t("TAG.TAGS") }}
                            </th>
                            <th class="pr-0 text-right" style="min-width: 80px">
                                {{ $t("TAG.SCANS") }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="product in paginatedProducts">
                            <tr v-bind:key="product.id">
                                <td class="pl-0">
                                    <router-link
                                            :to="{ name: 'product', params: { id: product.id } }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                    >{{ product.name }}
                                    </router-link
                                    >
                                </td>

                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.sku }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.gender }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.category }}</span
                    >
                                </td>
                                <td>
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.sub_category }}</span
                    >
                                </td>
                                <td class="text-right">
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.tag_count }}</span
                    >
                                </td>
                                <td class="pr-0 text-right">
                    <span
                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                    >{{ product.scan_count }}</span
                    >
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                    <b-pagination
                            v-if="filtered_products.length > pagination.per_page"
                            class="pt-5"
                            v-model="pagination.current_page"
                            :total-rows="filtered_products.length"
                            :per-page="pagination.per_page"
                            align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <div class="row" v-if="total_tags">
            <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{
                $t("TAG.TAGS")
              }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                $tc("REPORTS.BY_PRODUCT")
              }}</span>
                        </h3>
                    </div>
                    <div class="card-body py-0">
                        <apexchart
                                :options="tag_chart"
                                :series="tag_series"
                                type="bar"
                        ></apexchart>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{
                $t("TAG.SCANS")
              }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                $tc("REPORTS.BY_PRODUCT")
              }}</span>
                        </h3>
                    </div>
                    <div class="card-body py-0">
                        <apexchart
                                :options="scan_chart"
                                :series="scan_series"
                                type="bar"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="total_tags">
            <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{
                $t("TAG.SCANS")
              }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                $tc("REPORTS.BY_CATEGORY")
              }}</span>
                        </h3>
                    </div>
                    <div class="card-body pt-0 pb-5">
                        <apexchart
                                :options="scan_category_chart"
                                :series="scan_category_series"
                                type="donut"
                        ></apexchart>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 pt-5">
                        <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{
                $t("TAG.SCANS")
              }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                $tc("REPORTS.BY_GENDER")
              }}</span>
                        </h3>
                    </div>
                    <div class="card-body pt-0 pb-5">
                        <apexchart
                                :options="scan_gender_chart"
                                :series="scan_gender_series"
                                type="donut"
                        ></apexchart>
                    </div>
                </div>
            </div>
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import {mapGetters} from "vuex";
    import "@riophae/vue-treeselect/dist/vue-treeselect.css";

    export default {
        name: "productsstatistics",
        data() {
            return {
     date_to : new Date().toISOString().substr(0, 10),
            date_from: new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString().substr(0, 10),
                products: [],
                filtered_products: [],
                total_tags: 0,
                total_scans: 0,
                tag_series: [],
                export: [],


                tag_chart: {},
                scan_series: [],
                scan_chart: {},
                scan_category_series: [],
                scan_category_chart: {},
                scan_gender_series: [],
                scan_gender_chart: {},
                pagination: {
                    current_page: 1,
                    per_page: 10,
                },
                filter_options: [],
                filter_selected: [],
            };
        },
        mounted() {

            this.$store.dispatch(SET_BREADCRUMB, [
                {title: this.$t("REPORTS.STATISTICS")},
            ]);
            ApiService.get("products")
                .then(({data}) => {
                    this.products = data;
                })
                .then(() => ApiService.get("products/statistics?foo=1901-01-01&foo2=3999-01-01"))
                .then(({data}) => {

                    this.products.forEach((product, index) => {
                        var stat = data.find((stat) => stat.id === product.id);
                        this.products[index].tag_count =
                            stat && stat.tag_count ? stat.tag_count : 0;
                        this.products[index].scan_count =
                            stat && stat.scan_count ? stat.scan_count : 0;
                    });
                    this.products.sort((a, b) =>
                        a.scan_count < b.scan_count ? 1 : b.scan_count < a.scan_count ? -1 : 0
                    );
                    this.buildFilterOptions();
                    this.setFilter();
                 this.updateView();
                })
                .catch(() => {
                    //Just keep it empty
                });
        },
        watch: {

            filter_selected() {
                this.setFilter();

                this.updateView();
            },
        },
        methods: {
        signalChange: function(evt){

// alert(this.date_to);
this.foo= this.date_from;
this.foo2= this.date_to;
//alert(this.foo);
             ApiService.get("products")
                            .then(({data}) => {
                                this.products = data;
                            })
                            .then(() => ApiService.get("products/statistics?foo="+this.foo+"&foo2="+this.foo2))
                            .then(({data}) => {

                                this.products.forEach((product, index) => {
                                    var stat = data.find((stat) => stat.id === product.id);
                                    this.products[index].tag_count =
                                        stat && stat.tag_count ? stat.tag_count : 0;
                                    this.products[index].scan_count =
                                        stat && stat.scan_count ? stat.scan_count : 0;
                                });
                                this.products.sort((a, b) =>
                                    a.scan_count < b.scan_count ? 1 : b.scan_count < a.scan_count ? -1 : 0
                                );
                            //    this.buildFilterOptions();
                              this.setFilter();
                            this.updateView();
                            })
                            .catch(() => {
                                //Just keep it empty
                            });



                           this.$emit("change", evt);
                           },

            buildFilterOptions() {
                var skus = [];
                var names = [];
                var categories = [];
                var subcategories = [];
                var genders = [];
                this.products.forEach((product) => {



                    var sku = skus.find((ent) => ent.id === "sku." + product.sku);
                    if (!sku && product.sku) {
                        skus.push({
                            id: "sku." + product.sku,
                            label: product.sku,
                            customLabel: this.$t("PRODUCT.SKU") + ": " + product.sku,
                        });
                    }
                    var name = names.find((ent) => ent.id === "name." + product.name);
                    if (!name && product.name) {
                        names.push({
                            id: "name." + product.name,
                            label: product.name,
                            customLabel: this.$t("PRODUCT.NAME") + ": " + product.name,
                        });
                    }
                    var category = categories.find(
                        (ent) => ent.id === "category." + product.category
                    );
                    if (!category && product.category) {
                        categories.push({
                            id: "category." + product.category,
                            label: product.category,
                            customLabel: this.$t("PRODUCT.CATEGORY") + ": " + product.category,
                        });
                    }
                    var subcategory = subcategories.find(
                        (ent) => ent.id === "sub_category." + product.sub_category
                    );
                    if (!subcategory && product.sub_category) {
                        subcategories.push({
                            id: "sub_category." + product.sub_category,
                            label: product.sub_category,
                            customLabel:
                                this.$t("PRODUCT.SUB_CATEGORY") + ": " + product.sub_category,
                        });
                    }
                    var gender = genders.find(
                        (ent) => ent.id === "gender." + product.gender
                    );
                    if (!gender && product.gender) {
                        genders.push({
                            id: "gender." + product.gender,
                            label: product.gender,
                            customLabel: this.$t("PRODUCT.GENDER") + ": " + product.gender,
                        });
                    }
                });
                this.filter_options.push({
                    id: "names",
                    label: this.$t("PRODUCT.NAME"),
                    children: names,
                });
                this.filter_options.push({
                    id: "skus",
                    label: this.$t("PRODUCT.SKU"),
                    children: skus,
                });
                this.filter_options.push({
                    id: "genders",
                    label: this.$t("PRODUCT.GENDER"),
                    children: genders,
                });
                this.filter_options.push({
                    id: "categories",
                    label: this.$t("PRODUCT.CATEGORY"),
                    children: categories,
                });
                this.filter_options.push({
                    id: "subcategories",
                    label: this.$t("PRODUCT.SUB_CATEGORY"),
                    children: subcategories,
                });
            },
            setFilter() {



                if (!this.filter_selected.length) {



                    this.filtered_products = this.products;
                } else {
                    var filtered = [];

                    this.products.forEach((product) => {


                        if (
                            this.filter_selected.find((entry) => {
                                return entry.startsWith("sku.");
                            })
                        ) {
                            if (
                                !this.filter_selected.find((entry) => {
                                    return entry == "sku." + product.sku;
                                })
                            ) {
                                return;
                            }
                        }
                        if (
                            this.filter_selected.find((entry) => {
                                return entry.startsWith("name.");
                            })
                        ) {
                            if (
                                !this.filter_selected.find((entry) => {
                                    return entry == "name." + product.name;
                                })
                            ) {
                                return;
                            }
                        }
                        if (
                            this.filter_selected.find((entry) => {
                                return entry.startsWith("category.");
                            })
                        ) {
                            if (
                                !this.filter_selected.find((entry) => {
                                    return entry == "category." + product.category;
                                })
                            ) {
                                return;
                            }
                        }
                        if (
                            this.filter_selected.find((entry) => {
                                return entry.startsWith("sub_category.");
                            })
                        ) {
                            if (
                                !this.filter_selected.find((entry) => {
                                    return entry == "sub_category." + product.sub_category;
                                })
                            ) {
                                return;
                            }
                        }
                        if (
                            this.filter_selected.find((entry) => {
                                return entry.startsWith("gender.");
                            })
                        ) {
                            if (
                                !this.filter_selected.find((entry) => {
                                    return entry == "gender." + product.gender;
                                })
                            ) {
                                return;
                            }
                        }


                       filtered.push(product);
                    });
                    this.filtered_products = filtered;
                }
            },
                setFilter2() {



                            if (!this.filter_selected.length) {



                                this.filtered_products = this.export;
                            } else {
                                var filtered = [];

                                this.export.forEach((product) => {


                                    if (
                                        this.filter_selected.find((entry) => {
                                            return entry.startsWith("sku.");
                                        })
                                    ) {
                                        if (
                                            !this.filter_selected.find((entry) => {
                                                return entry == "sku." + product.sku;
                                            })
                                        ) {
                                            return;
                                        }
                                    }
                                    if (
                                        this.filter_selected.find((entry) => {
                                            return entry.startsWith("name.");
                                        })
                                    ) {
                                        if (
                                            !this.filter_selected.find((entry) => {
                                                return entry == "name." + product.name;
                                            })
                                        ) {
                                            return;
                                        }
                                    }
                                    if (
                                        this.filter_selected.find((entry) => {
                                            return entry.startsWith("category.");
                                        })
                                    ) {
                                        if (
                                            !this.filter_selected.find((entry) => {
                                                return entry == "category." + product.category;
                                            })
                                        ) {
                                            return;
                                        }
                                    }
                                    if (
                                        this.filter_selected.find((entry) => {
                                            return entry.startsWith("sub_category.");
                                        })
                                    ) {
                                        if (
                                            !this.filter_selected.find((entry) => {
                                                return entry == "sub_category." + product.sub_category;
                                            })
                                        ) {
                                            return;
                                        }
                                    }
                                    if (
                                        this.filter_selected.find((entry) => {
                                            return entry.startsWith("gender.");
                                        })
                                    ) {
                                        if (
                                            !this.filter_selected.find((entry) => {
                                                return entry == "gender." + product.gender;
                                            })
                                        ) {
                                            return;
                                        }
                                    }


                                   filtered.push(product);
                                });
                                this.filtered_products = filtered;
                            }
                        },
            updateView() {

                this.total_tags = this.filtered_products.reduce(function (
                    accumulator,
                    product
                    ) {
                        return accumulator + product.tag_count;
                    },
                    0);
                this.total_scans = this.filtered_products.reduce(function (
                    accumulator,
                    product
                    ) {
                        return accumulator + product.scan_count;
                    },
                    0);

                this.pagination.current_page = 1;
                this.updateCharts();
            },
            updateCharts() {
                this.tag_chart = {
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            dataLabels: {
                                position: "top",
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: -6,
                    },
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: [this.layoutConfig("colors.theme.base.danger")],
                    xaxis: {
                        categories: this.filtered_products
                            .filter((prod) => prod.tag_count > 0)
                            .map(({name}) => name)
                            .slice(0, 10),
                    },
                };
                this.tag_series = [
                    {
                        name: this.$t("TAG.TAGS"),
                        data: this.filtered_products
                            .filter((prod) => prod.tag_count > 0)
                            .map(({tag_count}) => tag_count)
                            .slice(0, 10),
                    },
                ];

                this.scan_chart = {
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            dataLabels: {
                                position: "top",
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: -6,
                    },
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    colors: [this.layoutConfig("colors.theme.base.success")],
                    xaxis: {
                        categories: this.filtered_products
                            .filter((prod) => prod.tag_count > 0)
                            .map(({name}) => name)
                            .slice(0, 10),
                    },
                };
                this.scan_series = [
                    {
                        name: this.$t("TAG.SCANS"),
                        data: this.filtered_products
                            .filter((prod) => prod.tag_count > 0)
                            .map(({scan_count}) => scan_count)
                            .slice(0, 10),
                    },
                ];

                var categories = [];
                this.filtered_products.forEach((product) => {
                    var stat = categories.find((cat) => cat.category === product.category);
                    var category = product.category
                        ? product.category
                        : this.$t("REPORTS.UNKNOWN");
                    if (stat) {
                        stat.scan_count += product.scan_count;
                    } else if (product.scan_count) {
                        categories.push({
                            category: category,
                            scan_count: product.scan_count,
                        });
                    }
                });

                this.scan_category_chart = {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        pie: {
                            expandOnClick: false,
                            donut: {
                                labels: {
                                    show: true,
                                },
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        dropShadow: {
                            enabled: false,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: "bottom",
                    },
                    labels: categories.map(({category}) => category),
                };
                this.scan_category_series = categories.map(
                    ({scan_count}) => scan_count
                );

                var genders = [];
                this.filtered_products.forEach((product) => {
                    var stat = genders.find((gen) => gen.gender === product.gender);
                    var gender = product.gender
                        ? product.gender
                        : this.$t("REPORTS.UNKNOWN");
                    if (stat) {
                        stat.scan_count += product.scan_count;
                    } else if (product.scan_count) {
                        genders.push({gender: gender, scan_count: product.scan_count});
                    }
                });

                this.scan_gender_chart = {
                    chart: {
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        pie: {
                            expandOnClick: false,
                            donut: {
                                labels: {
                                    show: true,
                                },
                            },
                        },
                    },
                    dataLabels: {
                        enabled: true,
                        dropShadow: {
                            enabled: false,
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        position: "bottom",
                    },
                    labels: genders.map(({gender}) => gender),
                };
                this.scan_gender_series = genders.map(({scan_count}) => scan_count);
            },

             downloadCSVData:function () {
this.foo= this.date_from;
this.foo2= this.date_to;
//alert(this.foo);
             ApiService.get("products")
                            .then(({data}) => {
                                this.products = data;
                            })
                            .then(() => ApiService.get("products/exproducts?foo="+this.foo+"&foo2="+this.foo2))
                            .then(({data}) => {

                                this.products.forEach((product, index) => {
                                    var stat = data.find((stat) => stat.id === product.id);
                                    this.products[index].tag_count =
                                        stat && stat.tag_count ? stat.tag_count : 0;
                                    this.products[index].scan_count =
                                        stat && stat.scan_count ? stat.scan_count : 0;
                                });
                                this.products.sort((a, b) =>
                                    a.scan_count < b.scan_count ? 1 : b.scan_count < a.scan_count ? -1 : 0
                                );
                            //    this.buildFilterOptions();
                            //    this.setFilter();
                              this.setFilter2();
                                this.export = data;


                        //    this.updateView();

                            })


                            .then(() => {


                                        const jsonObject = JSON.stringify(this.export);
                                        const csv2 = convertToCSV(jsonObject);

                                        const anchor = document.createElement('a');
                                        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv2);
                                        anchor.target = '_blank';
                                        anchor.download = 'STATISTIC.csv';
                                        anchor.click();
                                        })

                            .catch(() => {
                                                           //Just keep it empty
                                                       });



             }
        },
        computed: {

            ...mapGetters(["layoutConfig"]),
            paginatedProducts() {
                const start =
                    (this.pagination.current_page - 1) * this.pagination.per_page,
                    end = start + this.pagination.per_page;
                return this.filtered_products.slice(start, end);
            },
        },
        props: {
          foo: {
            type: String,
            default: '2000-01-01',
          }
        },

    };


    function convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = 'uid,sizename,ean,colorname,weight,year,origin,colorcode,productname,sku,category,sub_category,gender,latitude,longitude,scanned_at' + "\r\n";
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = '';
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ',';
          line += array[i][index];
        }
        str += line + '\r\n'; // eslint-disable-line
      }
      return str;
    }
</script>
